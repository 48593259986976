<template>
  <div class="ddj-of">
    <div class="main-Title bgff"><h2>OF列表</h2></div>
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row>
            <!-- <el-col :span="4">
							<el-form-item label="ID:"><el-input v-model="filter.offerId" placeholder="Please enter offer ID" class="mr10"></el-input></el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="Country:">
								<el-select v-model="filter.country" placeholder="Please select" class="mr10">
									<el-option v-for="item in options.country" :key="item.value" :value="item.value" :label="item.label"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="OS:" label-width="50px">
								<el-select v-model="filter.os" placeholder="Please select OS" class="mr10">
									<el-option v-for="item in options.os" :key="item.value" :value="item.value" :label="item.label"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="上游名称:">
								<el-select v-model="filter.advertiserName" placeholder="Please select Advertiser" class="mr10">
									<el-option v-for="item in options.advertiser" :key="item.value" :value="item.value" :label="item.label"></el-option>
								</el-select>
							</el-form-item>
						</el-col> -->
            <el-col :span="2"
              ><el-button type="primary" size="mini" @click="add()">添加</el-button></el-col
            >
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          style="width:100%;"
          height="70vmin"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="OF名称" prop="emuName"></el-table-column>
          <el-table-column label="OF networkAff" prop="networkAff"></el-table-column>
          <el-table-column label="token" prop="token"></el-table-column>
          <el-table-column label="PB链接" prop="postbackUrl"></el-table-column>
          <el-table-column label="添加时间" prop="createTime"></el-table-column>
          <el-table-column label="修改时间" prop="updateTime"></el-table-column>
          <el-table-column label="操作" width="210" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="edit(scope.row.id)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ddjEMUCon from '../../controllers/DDJ/emu/list';
  export default {
    name: 'ddjEMU',
    ...ddjEMUCon,
  };
</script>

<style></style>
